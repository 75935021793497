/**
 * Created by zeitler on 07-12-2016.
 */
let drop;
const CLASS_ACTIVE = 'drop_active';
const CLASS_SHOWABLE = 'drop_show'

// $ = django.jQuery;

$(document).ready(function () {
    console.log('asd');
    drop = new Droppable()
});

$(document).ready(function () {});

class Droppable{
    constructor(){
        this._registered = [];
        this.parent = this
        this.showing = this._showing
    }

    register(id_to_click, id_to_show){
        this._registered.push(
            new DroppableElement(this, id_to_click, id_to_show)
        )
    }

    _showing(idx){
        return idx < this._registered.length ? this._registered[idx].is_visible() : undefined
    }
}


class DroppableElement{
    constructor(parent, id_to_click, id_to_show, background_color='white'){
        if(typeof(id_to_click) != "object")
            this._clickable = document.getElementById(id_to_click);
        else{
            if (id_to_click instanceof $)
                this._clickable = id_to_click.get(0)
            else
                this._clickable = id_to_click
        }
        if(typeof(id_to_show) != "object")
            this._showable = document.getElementById(id_to_show);
        else{
            if (id_to_show instanceof $)
                this._showable = id_to_show.get(0)
            else
                this._showable = id_to_show;
        }
        this._cursor = 'pointer';
        this._createDropdown()
        this._set_styles()
        this._dropped = false;
        this._parent = parent
    }

    get background_color(){
        return this._background_color
    }

    set background_color(value){
        this._background_color = value
    }

    is_visible(){
        return this._showable.offsetWidth > 0 && this._showable.offsetHeight > 0
    }

    inspect_another_open(){
        for (var i=0; i<this._parent._registered.length; i++){
            if (this._parent._registered[i] != this){
                if(this._parent.showing(i))
                    this._parent._registered[i]._clickable.click()
            }
        }
    }

    _createDropdown(){
        /*
        Expects div with <span></span><ul></ul>
        span to click to open dropdown, and get the clicked value
        1 */
        var that = this;

        this._clickable.onclick = (function(){
            that.inspect_another_open()
            if (!that._dropped){
                that._clickable.classList.add(CLASS_ACTIVE)
            }
            $(that._showable).slideToggle('slow', function () {
                if(that._dropped) {
                    that._clickable.classList.remove(CLASS_ACTIVE)
                    that._dropped = false
                }
                else
                    that._dropped = true

            });
        })
    }

    _set_styles(){
        this._clickable.style.cursor = this._cursor;
        this._showable.classList.add(CLASS_SHOWABLE)
    }


}